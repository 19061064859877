import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/_actions/alertAction";
import { getSettings } from "../../redux/_actions/settingsAction";
import moment from "moment";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";
import { useParams } from "react-router";

const InvoiceReport = ({ invoice, settings, contact }) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [localPriceWOTaxes, setPriceWOTaxes] = useState({ priceWOTaxes: 0.0 });
  const [localTaxTotal, setTaxTotal] = useState({ tax: 0.0 });
  const [localTotalPrice, setTotalPrice] = useState({ price: 0.0 });
  const [taxRate, setTaxRate] = useState(0);

  const updateTotals = (lineItems) => {
    const lineItemTotal = lineItems.reduce(
      (prev, cur) => prev + cur.numberOfItems * cur.priceWOTaxes,
      0
    );
    if (localPriceWOTaxes.priceWOTaxes !== lineItemTotal) {
      let taxTotal;
      if (taxRate > 0) {
        taxTotal = lineItemTotal * (taxRate / 100);
      } else {
        taxTotal = 0;
      }

      const grandTotal = lineItemTotal + taxTotal;
      setPriceWOTaxes({
        ...localPriceWOTaxes,
        priceWOTaxes: parseFloat(lineItemTotal),
      });
      setTaxTotal({ ...localTaxTotal, tax: parseFloat(taxTotal) });
      setTotalPrice({ ...localTotalPrice, price: parseFloat(grandTotal) });
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("NL-nl", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  useEffect(() => {
    if (invoice && invoice.invoiceLines) {
      updateTotals(invoice.invoiceLines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  const downloadPDF = (e) => {
    e.preventDefault();
    const { target } = e;

    target.innerHTML = "<em>Downloading...</em>";
    target.classList.add("downloading");

    axios
      .get(`/api/invoice/download/${id}`, { responseType: "blob" })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, `factuur_${id}.pdf`);
        target.innerHTML = "Download factuur";
        target.classList.remove("downloading");
      })
      .catch((error) => {
        target.innerHTML = "Download factuur";
        target.classList.remove("downloading");
        dispatch(
          setAlert(
            "Er is iets misgegaan bij het downloaden van de factuur (.pdf), probeer het nogmaals aub.",
            "danger"
          )
        );
      });
  };

  const printInvoice = (event) => {
    event.preventDefault();
    window.print();
  };

  const mailInvoice = function (event) {
    event.preventDefault();
    //   const { target } = event;
    const invoiceId = event.target.getAttribute("data-id");
    history.push(`/invoice/send/${invoiceId}`);
  };

  useEffect(() => {
    dispatch(getSettings()).then((settings) => {
      if (settings) {
        let percentage = parseFloat(settings.taxPercentage);
        // if (percentage > 0) {
        //   percentage = percentage / 100;
        //  }
        setTaxRate(percentage);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div id="invoice-report" className="box-body invoice-report">
        <table className="table-header" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "33%", verticalAlign: "top" }}>
                <br />
                <br />
                <br />
                <br />
                <span className="bold">
                  {invoice ? invoice.contactName : ""}
                </span>
                <br />
                {contact ? contact.street : ""}{" "}
                {contact ? contact.houseNumber : ""} <br />
                {contact ? contact.postalCode : ""}{" "}
                {contact ? contact.city : ""}
                <br />
              </td>
              <td
                style={{
                  width: "34%",
                  verticalAlign: "top",
                  textAlign: "center",
                }}
              >
                <img
                  src={settings ? settings.companyLogo : ""}
                  alt="company logo"
                />
              </td>
              <td
                style={{
                  width: "33%",
                  verticalAlign: "top",
                  textAlign: "right",
                }}
              >
                <div className="bold">
                  {settings ? settings.companyName : ""}
                </div>
                {settings ? settings.street : ""}{" "}
                {settings ? settings.houseNumber : ""}
                <br />
                {settings ? settings.postalCode : ""}{" "}
                {settings ? settings.city : ""}
                <br />
                Kvk: {settings ? settings.chamberOfCommerceNumber : ""}
                <br />
                BTW: {settings ? settings.taxNumber : ""}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table report onelinertable nopaddingtable">
          <tbody>
            <tr>
              <td width="150px" className="padleft-0">
                Factuurnummer:
              </td>
              <td>
                {invoice && invoice.invoiceNumber ? invoice.invoiceNumber : ""}
              </td>
            </tr>
            <tr>
              <td className="padleft-0">Factuurdatum:</td>
              <td>
                {invoice && invoice.invoiceDate
                  ? moment(new Date(invoice.invoiceDate)).format("yyyy-MM-DD")
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="padleft-0">Vervaldatum:</td>
              <td>
                {invoice && invoice.payDate
                  ? moment(new Date(invoice.payDate)).format("yyyy-MM-DD")
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <br />
          <br />
          <br />
          <br />
        </div>

        <table className="table report onelinertable">
          <tbody>
            <tr>
              <th style={{ width: "60%" }}>Omschrijving</th>
              <th style={{ width: "65px" }}>Aantal</th>
              <th className="price">Bedrag</th>
              <th className="price last">Totaal</th>
            </tr>
            {invoice &&
              invoice.invoiceLines &&
              invoice.invoiceLines.map((invoiceLine) => (
                <tr key={invoiceLine.description}>
                  <td>{invoiceLine.description}</td>
                  <td className="price">{invoiceLine.numberOfItems}</td>
                  <td className="price">
                    {formatCurrency(invoiceLine.priceWOTaxes)}
                  </td>
                  <td className="price last">
                    {formatCurrency(
                      invoiceLine.priceWOTaxes ?? invoiceLine.numberOfItems
                        ? invoiceLine.priceWOTaxes * invoiceLine.numberOfItems
                        : "0"
                    )}
                  </td>
                </tr>
              ))}
            <tr className="top-line">
              <td></td>
              <td colSpan="2" className="bold">
                Totaal excl. BTW:
              </td>
              <td className="price last bold">
                {formatCurrency(localPriceWOTaxes.priceWOTaxes)}
              </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="2">BTW ({settings && settings.taxPercentage}):</td>
              <td className="price last">
                {formatCurrency(localTaxTotal.tax)}
              </td>
            </tr>
            <tr className="top-line">
              <td></td>
              <td colSpan="2" className="bold">
                Totaal:
              </td>
              <td className="price last bold">
                {formatCurrency(localTotalPrice.price)}
              </td>
            </tr>
          </tbody>
        </table>

        <div id="pageFooter" className="pad-45">
          <table className="table report onelinertable footer-table">
            <tbody>
              <tr>
                <td>
                  Bank
                  <div className="bold">
                    {settings ? settings.bankName : ""}
                  </div>
                </td>
                <td>
                  IBAN-nummer
                  <div className="bold">
                    {settings ? settings.bankIBAN : ""}
                  </div>
                </td>
                <td>
                  KvK Nummer
                  <div className="bold">
                    {settings ? settings.chamberOfCommerceNumber : ""}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="footer-text">
            Het openstaande bedrag dient binnen 30 dagen overgemaakt te zijn op
            rekeningnummer: <br />
            {settings ? settings.bankIBAN : ""} onder vermelding van
            factuurnummer: {invoice ? invoice.invoiceNumber : ""}
          </div>
        </div>
      </div>
      <div className="box-footer">
        <button
          onClick={downloadPDF}
          data-id={invoice ? invoice._id : ""}
          className="btn btn-primary"
        >
          Download factuur
        </button>
        <button
          onClick={mailInvoice}
          data-id={invoice ? invoice._id : ""}
          className="btn btn-primary"
        >
          Mail factuur
        </button>
        <button onClick={printInvoice} className="btn btn-primary">
          Print factuur
        </button>
      </div>
    </div>
  );
};
export default InvoiceReport;
